import { render, staticRenderFns } from "./phoneDetails.vue?vue&type=template&id=63fd0eec&scoped=true"
import script from "./phoneDetails.vue?vue&type=script&lang=js"
export * from "./phoneDetails.vue?vue&type=script&lang=js"
import style0 from "./phoneDetails.vue?vue&type=style&index=0&id=63fd0eec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fd0eec",
  null
  
)

export default component.exports